<template>
  <!-- hidden PageHeaderWrapper title demo -->
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
      <a-form :form="form">
        <a-row class="form-row" :gutter="16">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="资料标题"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <a-input
                v-model="formData.title"
                placeholder="请输入资料标题" />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
               label="发布日期"
              :labelCol="{lg: {span: 8}, sm: {span: 8}}"
              :wrapperCol="{lg: {span: 16}, sm: {span: 16} }">
              <a-date-picker
                      style="width: 180px"
                      @change="pubDateChange"
                      placeholder="请选择日期"
                      v-decorator="['pubdate',{initialValue:formData.pubdate}]"/>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="资料类型"
              :labelCol="{lg: {span: 8}, sm: {span: 8}}"
              :wrapperCol="{lg: {span: 16}, sm: {span: 16} }">
              <a-select v-model="formData.type" placeholder="请选择" style="width:180px">
                <a-select-option
                  :value="item.value"
                  v-for="item in menuList"
                  :selected="item.value == formData.type"
                  :key="item.id"
                >{{ item.description }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="资料内容"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <tinymce-editor ref="editor"
                              v-model="formData.content"
                              :disabled="disabled"
                              :base-url="baseUrl"
                              :language="language"
                              :skin="skin"
                              @onClick="onClick">
              </tinymce-editor>
            </a-form-item>
            <a-form-item
              label="附件"
              :labelCol="{lg: {span: 4}, sm: {span: 4}}"
              :wrapperCol="{lg: {span: 20}, sm: {span: 20} }">
              <a-upload
                      :multiple="true"
                      :fileList="downloadFiles"
                      :remove="handleDownloadFileRemove"
                      :customRequest="downloadFilesCustomRequest"
              >
                <a-button> <a-icon type="upload" /> 上传文件 </a-button>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          :wrapperCol="{ span: 24 }"
          style="text-align: center"
        >
          <a-button htmlType="submit" type="primary" @click="submit">提交</a-button>
          <a-button style="margin-left: 8px" @click="back">返回</a-button>
        </a-form-item>
      </a-form>
    </a-card>
</template>

<script>
import moment from 'moment'
import { getMenuList, addDoc, updateDoc, getDocInfo,upload } from '@/api/basic/common'

import TinymceEditor from './Editor/tinymce-editor'

export default {
  components: {
      TinymceEditor
  },
  created () {
    this.getMenu()
    console.log(localStorage)

    if(this.$route.query.id!=null)
    {
      this.formData.id = this.$route.query.id
      this.getInfo()
    }
  },
  data () {
    return {
      // form
      form: this.$form.createForm(this),
      // 表单数据
      formData: {
        content: '',
        fileIds: [],
        title: '',
        type: 1,
	type_name: ''
      },
        //tiny  参数
        disabled: false,
        baseUrl: process.env.NODE_ENV === 'production' ? '' : '',
        language: 'zh_CN',
        skin: 'oxide',
        // 查询参数
      menuParam: {
        menu: '资料类型'
      },
      // 接口返回数据
      menuList: [],
      downloadFiles: []
    }
  },
  methods: {
      //鼠标单击的事件
      onClick(e, editor) {
          console.log('Element clicked')
          console.log(e)
          console.log(editor)
      },
    pubDateChange (value) {
      // console.log(value)
      this.formData.pubdate = value.format('YYYY-MM-DD')
    },
    getInfo() {
      getDocInfo({ id: this.$route.query.id }).then(response => {
        this.formData = response.data
        //文件
        if(response.data.fileRelaxs != null) {
          for (var entity of response.data.fileRelaxs) {
            let file = this.fileFormatter(entity)
            // 上传单个文件后，将该文件会先到a-upload组件的已上传文件列表中的操作
            this.downloadFiles.push(file)
          }
        }
        this.formData.fileIds = []
      }).catch(err => {
        console.log(err)
      })
    },
    // 接口请求
    getMenu () {
      getMenuList(this.menuParam).then(response => {
        this.menuList = response.data.list
        this.formData.type = response.data.list[0].value
        this.formData.type_name = response.data.list[0].description
      })
    },
    // 操作方法
    submit () {

      for (var i = 0;i<this.downloadFiles.length;i++) {
        this.formData.fileIds.push(this.downloadFiles[i].uid)
      }

      if(this.formData.id!=null)
      {
        updateDoc(this.formData).then(response => {
          if (response.code === 0) {
            this.$message.info(`数据更新成功`)
            this.$router.back()
          } else {
            this.$message.error(`数据添加失败,错误原因:${response.msg}`)
          }
        })
      }
      else
      {
        addDoc(this.formData).then(response => {
          if (response.code === 0) {
            this.$message.info(`数据添加成功`)
            this.$router.back()
          } else {
            this.$message.error(`数据添加失败,错误原因:${response.msg}`)
          }
        })
      }
    },
    back () {
      this.$router.back()
    },
    // handler
    handleChange ({ file, fileList }) {
      if (file.status !== 'uploading') {
        console.log(file, fileList);
      }
    },
    // 重写a-upload的文件上传处理方式
    downloadFilesCustomRequest (data) {
      this.saveFile(data)
    },
    // 上传并保存文件
    saveFile (data){
      const formData = new FormData()
      formData.append('file', data.file)
      upload(formData).then((res) => {
        if (res.code === 0){
          let file = this.fileFormatter(res.data)
          // 上传单个文件后，将该文件会先到a-upload组件的已上传文件列表中的操作
          this.downloadFiles.push(file)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 对上传成功返回的数据进行格式化处理，格式化a-upload能显示在已上传列表中的格式（这个格式官方文档有给出的）
    fileFormatter(data) {
      let file = {
        uid: data.id,    // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
        name: data.fileName,   // 文件名
        status: 'done', // 状态有：uploading done error removed
        response: '{"status": "success"}', // 服务端响应内容
      }
      return file
    },
    // 文件删除处理
    handleDownloadFileRemove (file) {
      const index = this.downloadFiles.indexOf(file)
      const newFileList = this.downloadFiles.slice()
      newFileList.splice(index, 1)
      this.downloadFiles = newFileList
    }
  }
}
</script>
<style lang="less" scoped>
  .a-hover:hover {
    cursor: pointer;
  }
</style>
